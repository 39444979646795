import React from 'react';
import { connect } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { apiService } from '../../../common/apiCallService';
import * as actions from '../../../Redux/actions';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import AuthenticatorModal from '../Merchant2FA/AuthenticatorModal';
import VerificationModal from '../Merchant2FA/VerificationModal';
import { checkValueInSS, getHeaderButtonStyle } from '../../../common/theme';
const info_icon = "/common/info_icon.png";
const selectedCheckBoxIcon = "/common/crytoicons/checkbox_filled.png"
const emptyCheckBoxIcon = "/common/crytoicons/checkbox_empty.png"
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


const styles = {
    menu: (provided, state) => ({
        ...provided,
        width: 382,
        padding: 5,
        marginTop: 0,
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: "red",
        width: 20,
    }),
    container: (provided) => ({
        ...provided,
    }),
    control: (provided) => ({
        ...provided,
        //search bar compo
    }),
}

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 43,
    height: 22,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 40,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(26px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(18px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 23,
        height: 21,
        position: 'relative',
        bottom: 1.5,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 32 / 2,
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));
class Settings extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            allCryptos: [],
            allowedCryptos: [],
            callBackURL: "",
            supportEmail: "",
            showUpdateBtn: false,
            previousSetting: null,
            showSuccessAlert: false,
            showErrorAlert: false,
            showCurrencyDropdown: false,
            cryptoTooltip: false,
            emailTooltip: false,
            urlTooltip: false,
            protectionTooltip: false,
            is2faActivated: false,
            showAuthenticatorModal: false,
            verificationCode2fa: '',
            show2FAVerificationModal: false,
            colorMode: null,
            headerButtonDarkBgColour: null,
            headerButtonLightBgColour: null,
            headerLightTextColour: null,
            headerDarkTextColour: null,
        }
    }
    

    componentDidMount() {
        this.setState({ is2faActivated: localStorage.getItem("2FAStatus") == 'true' ? true : false });
        if (localStorage.getItem("token")) {
            this.getMerchantSettings()
        }
        if (checkValueInSS("colorMode")) {
            this.setState({ colorMode: sessionStorage.getItem("colorMode") });
        }
        if (checkValueInSS("headerButtonDarkBgColour")) {
            this.setState({ headerButtonDarkBgColour: sessionStorage.getItem("headerButtonDarkBgColour") });
        }
        if (checkValueInSS("headerButtonLightBgColour")) {
            this.setState({ headerButtonLightBgColour: sessionStorage.getItem("headerButtonLightBgColour") });
        }
        if (checkValueInSS("headerDarkTextColour")) {
            this.setState({ headerDarkTextColour: sessionStorage.getItem("headerDarkTextColour") });
        }
        if (checkValueInSS("headerLightTextColour")) {
            this.setState({ headerLightTextColour: sessionStorage.getItem("headerLightTextColour") });
        }
    }

    savePreviousSetting = () => {
        if (!this.state.previousSetting) {
            const CList = this.state.allowedCryptos.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
            this.setState({
                previousSetting: {
                    allowedCryptos: [...CList],
                    callBackURL: this.state.callBackURL,
                    supportEmail: this.state.supportEmail,
                }
            })
        }
    }

    handleChange = (e) => {
        
        if (this.state[e.target.name] !== e.target.value) {
            this.setState({
                showSuccessAlert: false,
                showErrorAlert: false,
                showUpdateBtn: true,
            })
            this.setState({
                [e.target.name]: e.target.value,
                showUpdateBtn: true,
                showSuccessAlert: false,
                showErrorAlert: false,
            });
        }
    }

    getMerchantSettings = () => {
        this.props.setLoading(true)
        apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/get-merchant-settings', {
            merchantId: localStorage.getItem("merchantId"),
        }, (data) => {
            this.props.setLoading(false)
            if (data && data.settings) {
                let allCryptoList = []
                let allowedCryptoList = []
                data.settings.allCryptoList.map((crypto) => {
                    allCryptoList.push({ label: crypto.name, value: crypto.symbol });
                })
                data.settings.allowedCryptoList.map((crypto) => {
                    allowedCryptoList.push(crypto.symbol);
                })
                allowedCryptoList = allowedCryptoList.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
                this.setState({
                    allCryptos: allCryptoList,
                    allowedCryptos: allowedCryptoList,
                    callBackURL: data.settings.callBackURL,
                    supportEmail: data.settings.supportEmail,
                }, () => {
                    this.savePreviousSetting()
                })
            }
        }, (err) => {
            this.props.setLoading(false)
            console.log("err");
        })
    }

    toggleSelection = (symbol) => {
        if (this.state.allowedCryptos.includes(symbol)) {
            // remove
            let newList = this.state.allowedCryptos.filter(symbolObj => {
                return (symbolObj !== symbol)
            }); 
            this.setState({ allowedCryptos: newList, showUpdateBtn: true });
            this.setState({ showSuccessAlert: false })
        } else {
            // add
            let newList = [...this.state.allowedCryptos];
            newList.push(symbol);
            newList = newList.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
            this.setState({ allowedCryptos: newList, showUpdateBtn: true });
            this.setState({ showSuccessAlert: false })

        }
    }

    updateSettings = () => {
        this.props.setLoading(true)
        let allowedCryptos = this.state.allowedCryptos
        this.setState({
            showSuccessAlert: false,
            showErrorAlert: false,
        })
        apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/update-merchant-settings', {
            merchantId: localStorage.getItem("merchantId"),
            callBackURL: this.state.callBackURL,
            supportEmail: this.state.supportEmail,
            allowedCryptoList: allowedCryptos && allowedCryptos.length > 0 ? allowedCryptos : "none",
        }, (data) => {
            this.props.setLoading(false)
            if (data.success) {
                this.setState({ showSuccessAlert: true })
                
                this.setState({
                    previousSetting: {
                        allowedCryptos: [...allowedCryptos],
                        callBackURL: this.state.callBackURL,
                        supportEmail: this.state.supportEmail,
                    }
                })
            } else {
                this.setState({ showErrorAlert: true })
            }
        }, (err) => {
            this.setState({ showErrorAlert: true })
            this.props.setLoading(false)
            console.log(err);
        })
    }


    handleToggle2FA = () => {
        if (this.state.is2faActivated === true) {
            this.setState({ show2FAVerificationModal: true });
        } else {
            this.setState({ showAuthenticatorModal: true });
        }
    };

    render() {
        let headerButtonStyle = getHeaderButtonStyle(this.state.colorMode, this.state.headerButtonDarkBgColour, this.state.headerDarkTextColour, this.state.headerButtonLightBgColour, this.state.headerLightTextColour)
        return (
            <div className="myAccSettingsMain">
                <div className="myAccTopPadder">
                    <div className="myAccTopShadow" />
                </div>
                <div className="subContainer">
                    <div className="myAccSettingsWrap">
                        <div className="myAccSettings_heading" data-e2e="MerchantSettings">
                            Merchant Settings
                        </div>
                        <div className="myAccSettings_heading_msg">
                            Configure your merchant settings to suit your preferences.
                        </div>
                        <div className="myAcc_settingItemWrap">
                            <div className="myAcc_settingItemNameWrap">
                                <div className="myAcc_settingItemName">
                                    Accepted Cryptocurrencies
                                </div>
                                <BootstrapTooltip title="Allowable Crypto" arrow placement="right-start" >
                                    <IconButton>
                                        <img src={`${this.props.image_base_url}${info_icon}`} alt="infoicon" className="myAcc_settingItemIcon"/>
                                    </IconButton>
                                </BootstrapTooltip>
                            </div>
                            <div className="FilterModalInputGroup myAcc_settingItemInputWrap" onClick={() => {
                                this.setState({
                                    showCurrencyDropdown: true,
                                })
                            }}>
                                <div className="FilterModalInputText" >
                                    {this.state.allowedCryptos.length === this.state.allCryptos.length ? "All" : `${this.state.allowedCryptos.length} Selected`}
                                </div>
                                <i className="fa fa-chevron-down FilterModalInputDropIcon" />
                            </div>
                            {this.state.showCurrencyDropdown && <div className="FilterModalCurrencyDropdownlist">
                                <div className="FM_currencyDrop_All" onClick={() => {
                                    this.setState({ showCurrencyDropdown: false })
                                }}>
                                    <span className="FM_currencyDrop_AllSelector">{this.state.allowedCryptos.length === this.state.allCryptos.length && this.state.allowedCryptos.length !== 0
                                        ? "All" : `${this.state.allowedCryptos.length} Selected`}</span>
                                    <span className="FilterModalInputDropUpIconWrap">
                                        <i className="fa fa-chevron-up FilterModalInputDropUpIcon" data-e2e="iconUp" />
                                    </span>
                                </div>
                                <div className="FilterModalCurrencyDropdownlistWrap">
                                    {this.state.allCryptos.map((crypto, index) => {
                                        let { value, label } = crypto;
                                        return (
                                            <div key={index} className="currencyListItemWrap" onClick={() => {
                                                this.toggleSelection(value);
                                            }}>
                                                <div className="currencyListIconNameWrap">
                                                    <img src={`${this.props.image_base_url}${this.state.allowedCryptos.includes(value) ? selectedCheckBoxIcon : emptyCheckBoxIcon}`}
                                                        data-e2e="checkBox" alt="checkbox" className="currencyListCheckBox" />
                                                    <div className="currencyList_Name" data-e2e={label}>
                                                        {label}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>}
                        </div>
                        <div className="myAcc_settingItemWrap">
                            <div className="myAcc_settingItemNameWrap">
                                <div className="myAcc_settingItemName">
                                    Call Back URL
                                </div>
                                <BootstrapTooltip title="Call Back URL" arrow placement="right-start" >
                                    <IconButton>
                                        <img src={`${this.props.image_base_url}${info_icon}`} alt="infoicon" className="myAcc_settingItemIcon"/>
                                    </IconButton>
                                </BootstrapTooltip>
                            </div>
                            <div className="myAcc_settingItemInputWrap">
                                <input type="text" name="callBackURL" value={this.state.callBackURL}
                                    onChange={this.handleChange}
                                    className="myAcc_settingItemInput" data-e2e="Input URL" placeholder="Input URL" />
                            </div>
                        </div>
                        <div className="myAcc_settingItemWrap">
                            <div className="myAcc_settingItemNameWrap">
                                <div className="myAcc_settingItemName">
                                    Set Support Email
                                </div>
                                <BootstrapTooltip title="Set Support Email" arrow placement="right-start" >
                                    <IconButton>
                                        <img src={`${this.props.image_base_url}${info_icon}`} alt="infoicon" className="myAcc_settingItemIcon"/>
                                    </IconButton>
                                </BootstrapTooltip>
                            </div>
                            <div className="myAcc_settingItemInputWrap">
                                <input type="text" name="supportEmail" value={this.state.supportEmail}
                                    onChange={this.handleChange}
                                    className="myAcc_settingItemInput" data-e2e="email" placeholder="email" />
                            </div>
                        </div>

                        <div className="myAcc_settingItemWrap">
                            <div className="myAcc_settingItemNameWrap">
                                <div className="myAcc_settingItemName">
                                    Protection
                                </div>
                                <BootstrapTooltip title="Google Authenticator two factor log in authentication" arrow placement="right-start" >
                                    <IconButton>
                                        <img src={`${this.props.image_base_url}${info_icon}`} alt="infoicon" className="myAcc_settingItemIcon"/>
                                    </IconButton>
                                </BootstrapTooltip>
                            </div>
                            <div className="w-100">
                                <div className="b2c-settings-header3 ">
                                    Google Authenticator two factor log in authentication - <span className="text-success">High Security</span>
                                </div>
                                <div className="cursor-pointer mt-3">
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <AntSwitch
                                            inputProps={{ 'aria-label': 'ant design' }}
                                            onChange={() => {
                                                this.handleToggle2FA();
                                            }}
                                            checked={this.state.is2faActivated === true ? true : false}
                                        />
                                    </Stack>
                                </div>
                            </div>
                        </div>

                        {this.state.showSuccessAlert && <div className="SuccessAlert" onClick={() => {
                            this.setState({ showSuccessAlert: false })
                        }}>Settings Updated Successfully.</div>}
                        {this.state.showErrorAlert && <div className="ErrorAlert">Something went wrong!</div>}
                        {this.state.showUpdateBtn && this.state.previousSetting && this.state.showCurrencyDropdown === false
                            && JSON.stringify(this.state.previousSetting) !== JSON.stringify({
                                allowedCryptos: this.state.allowedCryptos,
                                callBackURL: this.state.callBackURL,
                                supportEmail: this.state.supportEmail,
                            })
                            && <div className="updateMerchantSettings"
                                style={{ ...headerButtonStyle }}
                                onClick={() => {
                                    this.updateSettings()
                                }} data-e2e="Update Settings">Update Settings</div>}
                    </div>
                </div>
                {this.state.showAuthenticatorModal &&
                    <AuthenticatorModal setLoading={this.props.setLoading} open={this.state.showAuthenticatorModal}
                        onClose={() => this.setState({ showAuthenticatorModal: false })}
                        setIs2faActivated={() => this.setState({ is2faActivated: true })} />
                }
                {this.state.show2FAVerificationModal &&
                    <VerificationModal setLoading={this.props.setLoading} open={this.state.show2FAVerificationModal}
                        onClose={() => this.setState({ show2FAVerificationModal: false })}
                        is2faActivated={this.state.is2faActivated} setIs2faActivated={() => this.setState({ is2faActivated: false })}
                    />
                }
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        api_url: state.config.api_url,
        image_base_url: state.config.image_base_url,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLoading: (data) => dispatch(actions.setLoading(data)),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Settings)