import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';
import { apiService } from '../../../common/apiCallService';

import Switch from "@mui/material/Switch";

import { styled } from "@mui/material/styles";
import { checkValueInSS, getHeaderButtonStyle } from '../../../common/theme';

const closeImage = "/common/crytoicons/close.png";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  marginLeft: 30,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#00D355" : "#00D355",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const ConfigureModal = (props) => {
  let { data } = props;
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const api_url = useSelector((state) => state.config.api_url);
  const [trading, setTrading] = useState(data.autotradeToUSDT ? data.autotradeToUSDT : false);
  const [colorMode, setColorMode] = useState(null);
  const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
  const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
  const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
  const [headerLightTextColour, setheaderLightTextColour] = useState(null);
  useEffect(() => {
      if (checkValueInSS("colorMode")) {
          setColorMode(sessionStorage.getItem("colorMode"));
      }
      if (checkValueInSS("headerButtonDarkBgColour")) {
          setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
      }
      if (checkValueInSS("headerButtonLightBgColour")) {
          setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
      }
      if (checkValueInSS("headerDarkTextColour")) {
          setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
      }
      if (checkValueInSS("headerLightTextColour")) {
          setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
      }
  }, [])
  const toggleAutoTrade = () => {
    setTrading(!trading);
  };

  const handleSave = () => {
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/create-update-merchnat-wallet-automation',
      {
        merchantId: localStorage.getItem("merchantId"),
        trading,
        cryptoId: data.id
      },
      (data) => {
        props.onClose();
        props.getBalances();
      },
      (err) => {
        console.log(err);
      },
    );
  };

  let disable = false;

  let headerButtonStyle = getHeaderButtonStyle(colorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)

  return (
    <div>
      <Dialog onClose={props.onClose} open={props.open} >
        <div className="balance-exchange-modal">
          <div className='balance-header'>
            <div className="exchange-modal-header">{`Сonfigure ${data.coin}`}</div>
            <div className="FilterModalCloseButtonWrap balance-close"
              onClick={props.onClose}
            ><img src={`${image_base_url}${closeImage}`} alt="closeImage" className="FilterModalCloseButton" /></div>
          </div>

          <div className="configure_card">
            <div className="config_heading">{`AutoTrade Incoming Deposits to USDT`}</div>
            <div className='config_btn'>
              <AntSwitch
                inputProps={{ "aria-label": "ant design" }}
                onChange={() => { toggleAutoTrade(data) }}
                checked={trading}
              />
            </div>
          </div>

          <div className='FilterModalContent'>
            <div className='FilterModalBody'>
              <button disabled={disable} className={`border-0 FilterModalSave ${disable ? "DisableFilterModalSave mt-2" : ""}`}
                style={{ ...headerButtonStyle }}
                onClick={() => handleSave()}>
                {`SAVE`}
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default ConfigureModal