import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import ClearIcon from '@mui/icons-material/Clear';
import { apiService } from '../../../../common/apiCallService'
import { useSelector } from 'react-redux';
import { checkValueInSS, getHeaderButtonStyle } from '../../../../common/theme';
const imageUrl = '/homeb2c/delete-address.png'

const RemoveAddressModal = (props) => {
  const [id, setId] = useState(null)
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const api_url = useSelector((state) => state.config.api_url);
  const [b2cColorMode, setB2CColorMode] = useState(null);
  const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
  const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
  const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
  const [headerLightTextColour, setheaderLightTextColour] = useState(null);

  useEffect(() => {
    if (checkValueInSS("b2cColorMode")) {
      setB2CColorMode(sessionStorage.getItem("b2cColorMode"));
    }
    if (checkValueInSS("headerButtonDarkBgColour")) {
      setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
    }
    if (checkValueInSS("headerButtonLightBgColour")) {
      setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
    }
    if (checkValueInSS("headerDarkTextColour")) {
      setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
    }
    if (checkValueInSS("headerLightTextColour")) {
      setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
    }
  }, [])

  const deleteAddress = () => {
    apiService((window.location.hostname === "localhost" ? api_url : "") + '/restapi/delete-address', {
      id: id
    }, (data) => {
      props.loadAddress()
      props.onClose({
        open: false,
        id: null
      })
      props.setAlertData('Address details removed')
      props.setShowAlert(true)
      setTimeout(() => {
        props.setShowAlert(false)
      }, 3000)
    },
      (err) => {
        console.log('the error from API get address is ', err);
      })


  }

  const onSubmit = () => {
    if (id) {
      deleteAddress()
    }
  }

  useEffect(() => {
    if (props.openAddModal.open) {
      setId(props.openAddModal.data._id)
    }
  }, [props.openAddModal])

  let headerButtonStyle = getHeaderButtonStyle(b2cColorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)


  return (
    <div>
      <Dialog onClose={() => props.onClose({
        open: false,
        id: null
      })} open={props.openAddModal.open} className="remove-address-model-dialog" style={{ overflow: 'visible' }}>

        <div className="p-4 remove-address-modal">
          <div style={{ width: '100%', justifyContent: 'center', 'alignItems': 'center' }}>
            <img src={`${image_base_url}${imageUrl}`} className='remove-btn-image' />
          </div>
          <div >
            <div className='remove-content-container '>
              <div className='remove-address-header'>Remove Address</div>
              <p className='remove-address-header-child'>Are you sure you want to remove selected Address? This action cannot be undone.</p>
            </div>
            <div className="mt-3 remove-flex-input">
              <div
                className={'kyc-select-options kyc-select cursor-pointer remove-button-bitcoin-ctr'}

              >
                <div>
                  {' '}
                  <img src={`${image_base_url}${props.openAddModal.data ? props.openAddModal.data.cryptoId.icon : ""}`} alt="bitcoin" height={30} width={30} />{' '}
                  <span className="ml-10">{props.openAddModal.data ? props.openAddModal.data.cryptoId.name : ""}</span>
                </div>
              </div>
            </div>
          </div>


          <div className="mt-4 remove-button-margin align-button-container">
            <button
              className='add-address-modal-button remove-button-width'
              onClick={() => onSubmit()}
              data-e2e="remove-btn"
              style={{ ...headerButtonStyle }}
            >
              Remove
            </button>
            <button
              className='remove-address-go-back-btn remove-button_border'
              onClick={() => props.onClose({
                open: false,
                id: null
              })}
              data-e2e="go-back-btn"
            >
              Go Back
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default RemoveAddressModal