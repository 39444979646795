import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiService } from '../../../common/apiCallService';
import { Table } from 'reactstrap';
import { Menu, styled, MenuItem, Card, Dialog } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { setLoading } from "../../../Redux/actions";
import { checkValueInSS, getHeaderButtonStyle } from '../../../common/theme';
// const WAValidator = require('wallet-address-validator');

const StyledMenu = styled((props) => (
    <Menu
        PaperProps={{
            elevation: 0,
            sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    width: 10,
                    height: 10,
                    right: "50%",
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                },
            },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        {...props}
    />
))(({ theme }) => ({}));

const sattlementData = [
    {
        name: "settlement",
        id: 1
    },
    {
        name: "autotrade",
        id: 2
    },
    {
        name: "disabled",
        id: 3
    }
];

const allAutotradeCoin = [
    {
        name: "USDT",
        id: 1
    },
    {
        name: "USDC",
        id: 2
    }
];
const allPayoutFrom = [
    {
        name: "Balance",
        id: 1
    },
    {
        name: "USDT",
        id: 2
    },
    {
        name: "USDC",
        id: 3
    }
];

const Automation = () => {
    const image_base_url = useSelector((state) => state.config.image_base_url);
    const api_url = useSelector((state) => state.config.api_url);
    const dispatch = useDispatch();
    const [editAddressModal, setEditAddressModal] = useState(false);
    const [editAddress, setEditAddress] = useState("");
    const [error, setError] = useState("");
    const [toCryptoId, setToCryptoId] = useState(null);
    const [toUpdateCrypto, setToUpdateCrypto] = useState(null);
    const [colorMode, setColorMode] = useState(null);
    const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
    const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
    const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
    const [headerLightTextColour, setheaderLightTextColour] = useState(null);

    useEffect(() => {
        getMerchantAutomation();
        if (checkValueInSS("colorMode")) {
            setColorMode(sessionStorage.getItem("colorMode"));
        }
        if (checkValueInSS("headerButtonDarkBgColour")) {
            setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
        } 
        if (checkValueInSS("headerButtonLightBgColour")) {
            setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
        } 
        if (checkValueInSS("headerDarkTextColour")) {
            setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
        } 
        if (checkValueInSS("headerLightTextColour")) {
            setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
        }
    }, [])

    const [automationData, setAutomationData] = useState([]);

    const getMerchantAutomation = () => {
        dispatch(setLoading(true));
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/get-merchant-automation',
            {
                user_email: localStorage.getItem("user_name"),
                merchantId: localStorage.getItem('merchantId'),
            },
            (data) => {
                if (data) {
                    dispatch(setLoading(false));
                    setAutomationData(data.cryptos);
                }
            },
            (err) => {
                dispatch(setLoading(true));
                console.log(err);
            },
        );
    };
    const updateHandlerParent = () => {
        let payload = {
            user_email: localStorage.getItem("user_name"),
            merchantId: localStorage.getItem('merchantId'),
        }
        if (toCryptoId) {
            payload.cryptoId = toCryptoId
        }
        payload.walletAddress = editAddress === "N/A" ? null : editAddress;
        dispatch(setLoading(true));
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/create-merchant-automation',
            {
                payload,
            },
            (data) => {
                if (data) {
                    dispatch(setLoading(false));
                    setEditAddressModal(false);
                    getMerchantAutomation();
                }
            },
            (err) => {
                dispatch(setLoading(false));
                console.log(err);
            },
        );
    }

    const handelCloseEditModal = () => {
        setEditAddressModal(false);
    }

    let headerButtonStyle = getHeaderButtonStyle(colorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)
    
    return (
        <div className="myAccSettingsMain">
            <div className="myAccTopPadder">
                <div className="myAccTopShadow" />
            </div>

            <div className='subContainer'>
                <div className="TransactionTableMain">
                    {automationData && automationData.length > 0 && <div><Table responsive>
                        <tbody className="TransactionTable_BODY">
                            <tr>
                                <th className="TransactionTable_heading" width="19%" >
                                    <div className='first-table-header'>
                                        {`ASSET`}
                                    </div>
                                </th>
                                <th className="TransactionTable_heading" width="14%">{`AUTOMATION`}</th>
                                <th className="TransactionTable_heading" width="10%" >{`RECURRING PERIOD`}</th>
                                <th className="TransactionTable_heading" width="14%">{`CRYPTOCURRENCY`}</th>
                                <th className="TransactionTable_heading" width="14%">{`PAYOUT FROM`}</th>
                                <th className="TransactionTable_heading text-uppercase" width="15%">{`Wallet Address`}</th>
                                <th className="TransactionTable_heading" width="14%"></th>
                            </tr>
                            {automationData.map((TData, i) => {
                                return (

                                    <SingleAutomationTableRow
                                        image_base_url={image_base_url}
                                        setToUpdateCrypto={setToUpdateCrypto}
                                        toUpdateCrypto={toUpdateCrypto}
                                        index={i}
                                        key={i}
                                        setEditAddressModal={setEditAddressModal}
                                        setEditAddress={setEditAddress}
                                        setToCryptoId={setToCryptoId}
                                        data={TData}
                                        api_url={api_url}
                                        dispatch={dispatch}
                                        getMerchantAutomation={getMerchantAutomation}
                                    />
                                )
                            })}
                        </tbody>
                    </Table>
                        {
                            <div >
                                <Dialog open={editAddressModal} className="b2c-signup-model deposit_model_mobile">
                                    <div className="enable-authenticator-model" style={{ width: '461px' }}>
                                        <Card style={{ width: '461px' }} className="card-bg">
                                            <div className="enable-authenticator-header">
                                                <div></div>
                                                <div data-e2e="receive-crypto-window">{`Wallet Address`}</div>
                                                <div className="signup-close-icon" data-e2e="close-icon">
                                                    <ClearIcon onClick={() => handelCloseEditModal()} />
                                                </div>
                                            </div>

                                            <div className="crypto_heading">{`Edit Address`}</div>
                                            <div className="input_container mb-10">
                                                <input
                                                    className="input_box"
                                                    type="text"
                                                    value={editAddress}
                                                    onChange={(e) => {
                                                        setError("")
                                                        setEditAddress(e.target.value)
                                                        // if (toUpdateCrypto && toUpdateCrypto.symbol && toUpdateCrypto.symbo !== "") {
                                                        //     let validateCurrency = ["USDT", "USDC", "DAI"].includes(toUpdateCrypto.symbol) ? "ETH" : toUpdateCrypto.symbol
                                                        //     var valid = WAValidator.validate(e.target.value, validateCurrency);
                                                        //     if (validateCurrency === "LTC") {
                                                        //         valid = true;
                                                        //     }
                                                        //     if (!valid) {
                                                        //         console.error(`INVALID ${validateCurrency} address!`);
                                                        //         setError(`INVALID ${validateCurrency} address!`)
                                                        //     }
                                                        // }
                                                    }}
                                                    placeholder="Enter wallet address"
                                                />
                                            </div>

                                            {error != "" && <div style={{ color: "red", marginLeft: "20px", marginBottom: "10px" }}>{error}</div>}
                                            {error === "" && <div className="withdraw_btn"
                                                style={{ ...headerButtonStyle }}
                                                onClick={() => { updateHandlerParent() }}>
                                                <div>{`UPDATE`}</div>
                                            </div>}
                                        </Card>
                                    </div>
                                </Dialog>
                            </div>
                        }
                    </div>}
                </div>
            </div>

        </div>
    )
}

export default Automation;


const SingleAutomationTableRow = (props) => {
    let { data, image_base_url, index, api_url, dispatch, getMerchantAutomation, setEditAddress, setEditAddressModal, setToCryptoId } = props;
    const [settlementAnchor, setSettlementDropdown] = useState(null);
    const settlementDropdown = Boolean(settlementAnchor);
    const handleSettlementDropdown = () => {
        setSettlementDropdown(null);
    }
    const handleOpenSettlementDropDown = (event) => {
        setSettlementDropdown(event.currentTarget);
    };


    const [autotradeAnchor, setAutotradeDropdown] = useState(null);
    const [payoutFromAnchor, setPayoutFromDropdown] = useState(null);
    const autotradeDropdown = Boolean(autotradeAnchor);
    const payoutFromDropdown = Boolean(payoutFromAnchor);
    const handleAutotradeDropdown = () => {
        setAutotradeDropdown(null);
    }
    const handlePayoutFromDropdown = () => {
        setPayoutFromDropdown(null);
    }
    const handleOpenAutotradeDropdown = (e) => {
        setAutotradeDropdown(e.currentTarget);
    }
    const handleOpenPayoutFromDropdown = (e) => {
        setPayoutFromDropdown(e.currentTarget);
    }

    const [recurringData, setRecurrningData] = useState([{ name: "ASAP" }, { name: "Daily" }, { name: "Weekly" }]);
    const [recurringPeriodAnchor, setRecurringPeriodDropdown] = useState(null);
    const recurringPeriodDropdown = Boolean(recurringPeriodAnchor);

    const handleRecurringPeriodDropdown = () => {
        setRecurringPeriodDropdown(null);
    }
    const handleOpenRecurringPeriodDropdown = (e) => {
        setRecurringPeriodDropdown(e.currentTarget);
    }


    const [selectedSettlement, setSettlementSelected] =
        useState(data.automation && data.automation.automation ? data.automation.automation : "settlement");
    const [autotradeData, setAutotradeData] =
        useState((data.automation && data.automation.autotradeTo) ? data.automation.autotradeTo.symbol : "USDT");
    const [payoutFromData, setPayoutFromData] =
        useState((data.automation && data.automation.payoutFrom) ? data.automation.payoutFrom : "Balance");

    const [selectedRecurringPeriod, setRecurringPeriod] =
        useState(data.automation && data.automation.recurringPeriod ? data.automation.recurringPeriod : "ASAP");
    const [saveChangeBtn, setSaveChangeBtn] = useState(false);

    const updateHandler = () => {
        let payload = {
            user_email: localStorage.getItem("user_name"),
            merchantId: localStorage.getItem('merchantId'),
            cryptoId: props.toUpdateCrypto._id,
            payoutFrom: payoutFromData,
        }
        if (selectedSettlement === "autotrade") {
            payload.automation = selectedSettlement;
            payload.autotrade = autotradeData;
        }
        if (selectedSettlement === "disabled") {
            payload.automation = selectedSettlement;
        }
        if (selectedSettlement === "settlement") {
            payload.automation = selectedSettlement;
            payload.recurringPeriod = selectedRecurringPeriod;
        }
        dispatch(setLoading(true));
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/create-merchant-automation',
            {
                payload,
            },
            (data) => {
                if (data) {
                    dispatch(setLoading(false));
                    setSaveChangeBtn(false);
                    setEditAddressModal(false);
                    getMerchantAutomation();
                }
            },
            (err) => {
                dispatch(setLoading(false));
                console.log(err);
            },
        );
    }

    const handleWalletAddressChange = (ele) => {
        props.setToUpdateCrypto(ele.crypto);
        setEditAddress(ele.automation && ele.automation.walletAddress ? ele.automation.walletAddress : "")
        setToCryptoId(ele.crypto._id)
        setEditAddressModal(true);
    }

    return (
        <tr className="CP_TableRow" key={index}>
            <td className="automation_item transactionsTable_item bl">
                <div className='balance-row-el crypto-row'>
                    <img className="USTImages" src={`${image_base_url}${data.crypto.icon}`} alt={data.crypto.name} />
                    <span>
                        {data.crypto.symbol ? data.crypto.symbol : 'N/A'} / <span className='automation_table_crypto_name'>{data.crypto.name ? data.crypto.name : 'N/A'}</span>
                    </span>
                </div>
            </td>

            <td className="transactionsTable_item">
                <span className="d-flex align-items-center" onClick={handleOpenSettlementDropDown}><button
                    style={{ marginTop: "2px", fontWeight: "normal" }}
                    className={`${settlementDropdown
                        ? "navItemActive navItemWrapA"
                        : "navItemWrapA text-dark text-capitalize"
                        } nav-btn-right text-dark text-capitalize`}
                    id="demo-customized-button"
                    aria-controls={
                        settlementDropdown
                            ? "demo-customized-menu"
                            : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={
                        settlementDropdown ? "true" : undefined
                    }
                    variant="contained"
                >
                    {selectedSettlement}
                </button>
                    <i data-e2e={`drop-down-icon-${data && data.crypto && data.crypto.name && data.crypto.name}`}
                        className={`mt-0 fa fa-chevron-${settlementAnchor ? "up" : "down"
                            } FilterModalInputDropUpIcon`}
                    />
                </span>
            </td>


            <td className="transactionsTable_item" >
                {
                    selectedSettlement === "autotrade" || selectedSettlement === "disabled" ? null :
                        <span className="d-flex align-items-center" onClick={handleOpenRecurringPeriodDropdown}><button
                            style={{ marginTop: "2px", fontWeight: "normal" }}
                            className={`${recurringPeriodDropdown
                                ? "navItemActive navItemWrapA"
                                : "navItemWrapA text-dark"
                                } nav-btn-right text-dark`}
                            id="demo-customized-button"
                            aria-controls={
                                recurringPeriodDropdown
                                    ? "demo-customized-menu"
                                    : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                                recurringPeriodDropdown ? "true" : undefined
                            }
                            variant="contained"
                        >
                            {selectedRecurringPeriod}
                        </button>
                            <i
                                className={`mt-0 fa fa-chevron-${recurringPeriodAnchor ? "up" : "down"
                                    } FilterModalInputDropUpIcon`}
                            />
                        </span>
                }

                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={recurringPeriodAnchor}
                    open={recurringPeriodDropdown}
                    onClose={handleRecurringPeriodDropdown}
                >
                    {recurringData.map((ele, i) => {
                        return (
                            <MenuItem key={i}
                                onClick={() => { setRecurringPeriod(ele.name); setSaveChangeBtn(true); handleRecurringPeriodDropdown(); props.setToUpdateCrypto(data.crypto); }}
                                disableRipple >
                                {ele.name}
                            </MenuItem>
                        );
                    })}
                </StyledMenu>
            </td>

            <td className="transactionsTable_item">
                {selectedSettlement === "settlement" || selectedSettlement === "disabled" ? null :
                    <span className="d-flex align-items-center" onClick={handleOpenAutotradeDropdown}><button
                        style={{ marginTop: "2px", fontWeight: "normal" }}
                        className={`${autotradeDropdown
                            ? "navItemActive navItemWrapA"
                            : "navItemWrapA text-dark"
                            } nav-btn-right text-dark`}
                        id="demo-customized-button"
                        aria-controls={
                            autotradeDropdown
                                ? "demo-customized-menu"
                                : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={
                            autotradeDropdown ? "true" : undefined
                        }
                        variant="contained"
                        data-e2e={`Autotrade to  ${autotradeData}`}
                    >
                        {`Autotrade to  ${autotradeData}`}
                    </button>
                        <i data-e2e={autotradeData}
                            className={`mt-0 fa fa-chevron-${autotradeAnchor ? "up" : "down"
                                } FilterModalInputDropUpIcon`}
                        />
                    </span>
                }
            </td>

            <td className="transactionsTable_item">
                <span className="d-flex align-items-center" onClick={handleOpenPayoutFromDropdown}><button
                    style={{ marginTop: "2px", fontWeight: "normal" }}
                    className={`${payoutFromDropdown
                        ? "navItemActive navItemWrapA"
                        : "navItemWrapA text-dark"
                        } nav-btn-right text-dark`}
                    id="demo-customized-button"
                    aria-controls={
                        payoutFromDropdown
                            ? "demo-customized-menu"
                            : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={
                        payoutFromDropdown ? "true" : undefined
                    }
                    variant="contained"
                >
                    {`Pay-out From  ${payoutFromData}`}
                </button>
                    <i data-e2e={`payout-icon-${data && data.crypto && data.crypto.name && data.crypto.name}`}
                        className={`mt-0 fa fa-chevron-${payoutFromAnchor ? "up" : "down"
                            } FilterModalInputDropUpIcon`}
                    />
                </span>
            </td>

            <td className="transactionsTable_item">
                {
                    selectedSettlement === "autotrade" || selectedSettlement === "disabled" ? null :
                        <span
                            onClick={() => handleWalletAddressChange(data)}
                        >
                            {`${(data.automation && data.automation.walletAddress && data.automation.walletAddress.length > 12) ?
                                (data.automation.walletAddress.substring(0, 8) +
                                    "....." +
                                    data.automation.walletAddress.substring(
                                        data.automation.walletAddress.length - 4,
                                        data.automation.walletAddress.length
                                    )) : (data.automation && data.automation.walletAddress ? data.automation.walletAddress : "N/A")}`}
                            <span className='automation_table_pencil'><i className="fa fa-pencil" aria-hidden="true"></i>
                            </span>
                        </span>
                }
            </td>

            <td className="transactionsTable_item">
                {
                    saveChangeBtn &&
                    <div className='automation_save_changes' onClick={() => updateHandler()}>
                        <div data-e2e={`Save Changes`}>{`Save Changes`}</div>
                    </div>
                }
            </td>


            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={settlementAnchor}
                open={settlementDropdown}
                onClose={handleSettlementDropdown}
            >
                {sattlementData.map((ele, i) => {
                    return (
                        <MenuItem key={i}
                        data-e2e={ele.name}
                            className="text-capitalize"
                            onClick={() => { setSettlementSelected(ele.name); props.setToUpdateCrypto(data.crypto); handleSettlementDropdown(); setSaveChangeBtn(true) }}
                            disableRipple >
                            {ele.name}
                        </MenuItem>
                    );
                })}
            </StyledMenu>

            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={autotradeAnchor}
                open={autotradeDropdown}
                onClose={handleAutotradeDropdown}
            >
                {allAutotradeCoin.map((ele, i) => {
                    return (
                        <MenuItem key={i}
                            onClick={() => { setAutotradeData(ele.name); setSaveChangeBtn(true); props.setToUpdateCrypto(data.crypto); handleAutotradeDropdown(); }}
                            disableRipple >
                            {ele.name}
                        </MenuItem>
                    );
                })}
            </StyledMenu>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={payoutFromAnchor}
                open={payoutFromDropdown}
                onClose={handlePayoutFromDropdown}
            >
                {allPayoutFrom.map((ele, i) => {
                    return (
                        <MenuItem key={i}
                        data-e2e={ele.name}
                            onClick={() => { setPayoutFromData(ele.name); setSaveChangeBtn(true); props.setToUpdateCrypto(data.crypto); handlePayoutFromDropdown(); }}
                            disableRipple >
                            {ele.name}
                        </MenuItem>
                    );
                })}
            </StyledMenu>
        </tr>
    )
}