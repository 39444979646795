export const constants = {
    B2C_LOGIN_PATH: "/login",
    B2C_SIGNUP_PATH: "/signup",
    WORLD_CARD_INTEGRATION_TYPE: "WorldCard",
    BAERS_CREST_INTEGRATION_TYPE: "Baers Crest",
    PAYMENT_EMPIRE_INTEGRATION_TYPE: "PaymentEmpire",
    NEVO4_INTEGRATION_TYPE: "Nevo4",
    GOLDEN_GATE_PAYMENTS_INTEGRATION_TYPE: "Golden Gate Payments",
    FINTECH_INTEGRATION_TYPE: "Fintech",
    SWITCH_FIN_INTEGRATION_TYPE: "SwitchFin Payments",
    COIN_SPIRIT_FOOTER_TEXT: "Coins Spirit is a brand of CPAD Services Sp. Z.O.O (0001022832) with the cryptocurrency business registration certificate number 2401-CKRDST.4225.100.2023. For more information contact ",
    COIN_SPIRIT_FOOTER_ADDRESS: "CPAD Services registered address: Ul Hoza, No. 86, apt. 210 seats, 00-682, Warsaw, Poland.",
    COIN_SWAP_CENTER_FOOTER_TEXT: "Coins Swap Center is a brand of Alfortar S.r.o (19092261) and is registered as a Virtual Asset Service Provider to offer provision of services connected with a virtual asset. For more information contact ",
    COIN_SWAP_CENTER_FOOTER_ADDRESS: "Alfortar registered address: Cimburkova 916/8, Žižkov, 130 00 Praha 3 ",
    COIN_SWAP_CENTER_FOOTER_NOTE: "Please note that we do not provide accounts or services for users that are based or are residents in the UK and USA",
    USDT_CRYPTO_ID: "USDT",
    USD_CURRENCY: "USD",
    USD_SYMBOL: "$",
    USDT_IMG_PATH: "/cryptonpay/usdt.png",
    TRC20_USDT_CRYPTO_ID: "TRC20-USDT",
    TRX_CRYPTO_ID: "TRX",
    SEARCH_DELAY_TIME: 1000
}