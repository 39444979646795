import React, { useEffect, useState } from 'react';
import RoutesList from './client//Routes.js';
import { useDispatch, useSelector } from 'react-redux';
import Loading from './client/Loading';
import { keepTheme } from './client/common/theme';
import localDb from './client/common/localDb';
import { setAndGetB2CTheme, setAndGetTheme } from './client/Redux/actions/commonAction';
import i18n from './locale/Localisation';
import { I18nextProvider } from 'react-i18next';
import "./scss/css/bootstrap.min.css"
import "./scss/css/font-awesome.css"
import "./scss/style.scss"
import "./scss/deposit.scss"

function App(props) {
  const dispatch = useDispatch();
  const [fontFamily, setFontFamily] = useState("Open Sans")
  const activity_tracking_api_url = useSelector((state) => state.config.activity_tracking_api_url);
  const api_url = useSelector((state) => state.config.api_url);
  const loading = useSelector((state) => state.common.loading);

  useEffect(() => {
    localStorage.setItem('api_url', window.location.hostname === 'localhost' ? api_url : '');
    localStorage.setItem('activity_tracking_api_url', window.location.hostname === 'localhost' ? activity_tracking_api_url : '');
    setFontFamily(sessionStorage.getItem("font") && sessionStorage.getItem("font") !== "" && sessionStorage.getItem("font") !== "null" ? sessionStorage.getItem("font") : fontFamily)
    dispatch(setAndGetTheme(localDb.getSSVal("colorMode")));
    dispatch(setAndGetB2CTheme(localDb.getSSVal("b2cColorMode")));

    if (localDb.getSSVal("colorMode")) {
      if (localDb.getSSVal("colorMode") === 'dark') {
        dispatch(setAndGetTheme('dark'));
      } else if (localDb.getSSVal("colorMode") === 'light') {
        dispatch(setAndGetTheme('light'));
      }
    }
    if (localDb.getSSVal("b2cColorMode")) {
      if (localDb.getSSVal("b2cColorMode") === 'dark') {
        dispatch(setAndGetB2CTheme('dark'));
      } else if (localDb.getSSVal("b2cColorMode") === 'light') {
        dispatch(setAndGetB2CTheme('light'));
      }
    }
  }, []);

  useEffect(() => {
    keepTheme();
  });

  return (
    <I18nextProvider i18n={i18n}>
        <div id="ProjectApp" className="projectApp">
          {loading && <Loading />}
          <RoutesList/>
        </div>
    </I18nextProvider>
  );
}

export default App;
