import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Table } from 'reactstrap';
import { apiService } from '../../../common/apiCallService';
import * as actions from '../../../Redux/actions';
import MediaQuery from 'react-responsive';
import { Navigate, useNavigate } from 'react-router-dom';
const keywarningicon = "/common/crytoicons/warning.png";

class APIKeys extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            TableData: [],
            revealedDate: null,
            merchantKeys: null,
            paymentProviderName: null,
        }
    }

    componentDidMount() {
        if (localStorage.getItem("token")) {
            this.getMerchantKeys()
        }  
        if (sessionStorage.getItem("paymentProviderName") && sessionStorage.getItem("paymentProviderName") !== "" && sessionStorage.getItem("paymentProviderName") !== "null") {
            this.setState({ paymentProviderName: sessionStorage.getItem("paymentProviderName") });
        }
    }

    getMerchantKeys = () => {
        this.props.setLoading(true);
        apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/get-apikeys', {
            merchantId: localStorage.getItem("merchantId"),
        }, (data) => {
            this.props.setLoading(false)
            let merchantKeys = []
            if (data && data.apiKeys && data.apiKeys.testApiKey) {
                merchantKeys.push(
                    {
                        keyName: "Test Key",
                        token: data.apiKeys.testApiKey.key,
                        hide: false,
                        lastUsed: data.apiKeys.testApiKey.lastUsedDate,
                        created: data.apiKeys.testApiKey.createdDate,
                    })
            }
            if (data && data.apiKeys && data.apiKeys.prodApiKey) {
                merchantKeys.push({
                    keyName: "Secret Key",
                    token: data.apiKeys.prodApiKey.key,
                    hide: true,
                    lastUsed: data.apiKeys.prodApiKey.lastUsedDate,
                    created: data.apiKeys.prodApiKey.createdDate,
                })
            }
            this.setState({ merchantKeys, revealedDate: data.apiKeys.revealedDate })
        }, (err) => {
            this.props.setLoading(false)
            console.log(err);
        })
    }

    updateKeyRevealed = () => {
        this.props.setLoading(true);
        apiService((window.location.hostname === "localhost" ? this.props.api_url : "") + '/restapi/update-key-revealed', {
            merchantId: localStorage.getItem("merchantId"),
        }, (data) => {
            this.props.setLoading(false)
            if (data && data.revealedDate) {
                this.setState({ revealedDate: data.revealedDate })
            }
        }, (err) => {
            this.props.setLoading(false)
            console.log(err);
        })
    }

    render() {
        return (
            <div className="myAccAPIKeysMain">
                <div className="myAccTopPadder">
                    <div className="myAccTopShadow" />
                </div>
                <div className="subContainer">
                    <div className="myAccAPIKeysWrap">
                        <div className="myAccAPIKeys_heading">
                            API Keys
                        </div>
                        <div className="myAccAPIKeys_heading_msg">
                         These keys will allow you to authenticate API requests. <br/>
                            Test key is for transactions using testnet. Do not send cryptocurrency on mainnet using this key.<span onClick={() => {
                                this.props.navigate("/merchantTools/APIGuide")
                            }} className="myAccAPIKeys_heading_msg_link">Learn more</span>
                        </div>
                        {this.state.merchantKeys && this.state.merchantKeys.length > 0 ? <div><MediaQuery minWidth={481}><APIKeysTable
                            image_base_url={this.props.image_base_url}
                            updateKeyRevealed={this.updateKeyRevealed}
                            revealedDate={this.state.revealedDate} data={this.state.merchantKeys} /></MediaQuery>
                            <MediaQuery maxWidth={480}><APIKeysComponent
                                image_base_url={this.props.image_base_url}
                                updateKeyRevealed={this.updateKeyRevealed}
                                revealedDate={this.state.revealedDate} data={this.state.merchantKeys} /></MediaQuery>
                        </div>
                            : <div className="myAccAPIKeys_not_available">Merchant keys not found!</div>}
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        api_url: state.config.api_url,
        image_base_url: state.config.image_base_url,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLoading: (data) => dispatch(actions.setLoading(data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)((props)=><APIKeys {...props} navigate={useNavigate()}/>)


const APIKeysTable = (props) => {
    const [revel, toggleRevelFlag] = useState(false);
    const [paymentProviderName, setPaymentProviderName] = useState(null)
    useEffect(() => {
        if (sessionStorage.getItem("paymentProviderName") && sessionStorage.getItem("paymentProviderName") !== "" && sessionStorage.getItem("paymentProviderName") !== "null") {
            setPaymentProviderName(sessionStorage.getItem("paymentProviderName"));
        }
  }, []);
    return (
        <div className="APIKeysTableMain">
            <script>{"\
                    .table-responsive {\
                        overflow: initial;\
                    }\
                    "}</script>
            <div className="APIKeysTableWrap">
                <Table>
                    <tbody className="APIKeysTable_BODY">
                        <tr>
                            <th className="APIKeysTable_heading" width="20%">Name</th>
                            <th className="APIKeysTable_heading" width="35%">Token</th>
                            <th className="APIKeysTable_heading" width="15%">Last Used</th>
                            <th className="APIKeysTable_heading" width="15%">Created</th>
                            <th className="APIKeysTable_heading" width="15%">Revealed Date</th>
                        </tr>
                        {props.data.map((TData, i) => {
                            return (
                                <SingleAPIKeysTableRow
                                    updateKeyRevealed={props.updateKeyRevealed}
                                    revealedDate={props.revealedDate}
                                    revel={revel}
                                    toggleRevelFlag={toggleRevelFlag}
                                    TData={TData} key={i} />
                            )
                        })
                        }
                    </tbody>
                </Table>
            </div>
            {revel && <div className="sKeyRevealWarningWrap">
                <div className="warningHeading">
                    <img src={`${props.image_base_url}${keywarningicon}`} alt="keywarningicon" className="warningIconWrapCircle" />
                    <div className="warningHeading_title">Warning</div>
                </div>
                <div className="sKeyRevealWarningText">{`You must keep your secret API key confidential and only store them on your own servers. You must not share your secret API key with any third parties. Your account’s secret API key can perform any API request to ${paymentProviderName ? paymentProviderName : "Crypton"} Payments without restriction.`} </div>
            </div>}
        </div>
    )
}

const APIKeysComponent = (props) => {
    const [revel, toggleRevelFlag] = useState(false);
    return (<div className="APIKeysComponentMain">
        {props.data.map((TData, i) => {
            return (<SingleAPIKeysComponentRow
                updateKeyRevealed={props.updateKeyRevealed}
                revealedDate={props.revealedDate}
                image_base_url={props.image_base_url}
                revel={revel}
                toggleRevelFlag={toggleRevelFlag}
                TData={TData} key={i} />
            )
        })}
    </div>
    )
}

const SingleAPIKeysTableRow = (props) => {
    let { TData, revel, toggleRevelFlag, updateKeyRevealed, revealedDate } = props;
    const [showDropdown, toggleDropdown] = useState(false);
    return (
        <tr>
            <td className="APIKeysTable_item">{TData.keyName}</td>
            {TData.hide ? <td className="APIKeysTable_item">{revel
                ? <div className="APIKeysTable_itemTokenWrap">
                    <div className="APIKeysTable_itemToken cursor-pointer" data-e2e="testKeys">
                        <span onClick={() => {
                            toggleDropdown(!showDropdown)
                        }}>
                            <span>{TData.token.substring(0, 25) + "..."}</span>
                            <i data-e2e="secretKey" className={`fa fa-angle-${showDropdown ? "up" : "down"} APIKeysTable_item_dropIcon`} />
                        </span>
                        &nbsp;&nbsp;<span data-e2e="revealAndHideBtn" className="revealAndHideBtn" onClick={() => {
                            toggleRevelFlag(false)
                            toggleDropdown(false)
                        }}>Hide</span>
                    </div>
                    {showDropdown && <div className="APIKeysTable_itemTokenDropdownContent" data-e2e={TData.token}>{TData.token}</div>}
                </div>
                : <div className="revealAndHideBtn"
                    onClick={() => {
                        toggleRevelFlag(true)
                        if (!revealedDate) {
                            updateKeyRevealed()
                        }
                    }} data-e2e="revealSecretKey">Reveal Secret Key</div>
            }</td>
                : <td className="APIKeysTable_item">
                    <div className="APIKeysTable_itemToken cursor-pointer" data-e2e="testKeys" onClick={() => {
                        toggleDropdown(!showDropdown)
                    }}>
                        <span>{TData.token.substring(0, 25) + "..."}</span>
                        <i className={`fa fa-angle-${showDropdown ? "up" : "down"} APIKeysTable_item_dropIcon`} />
                    </div>
                    {showDropdown && <div className="APIKeysTable_itemTokenDropdownContent">{TData.token}</div>}
                </td>
            }
            <td className="APIKeysTable_item">{TData.lastUsed ? moment(TData.lastUsed, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("D MMMM") : "-"}</td>
            <td className="APIKeysTable_item">{TData.created ? moment(TData.created, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("D MMMM") : "N/A"}</td>
            <td className="APIKeysTable_item">{revealedDate && TData.keyName === "Secret Key" ? moment(revealedDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("D MMMM") : "-"}</td>
        </tr>
    )
}
const SingleAPIKeysComponentRow = (props) => {
    let { TData, revel, toggleRevelFlag, updateKeyRevealed, revealedDate } = props;
    const [showDropdown, toggleDropdown] = useState(false);
    const [paymentProviderName, setPaymentProviderName] = useState(null)
    useEffect(() => {
        if (sessionStorage.getItem("paymentProviderName") && sessionStorage.getItem("paymentProviderName") !== "" && sessionStorage.getItem("paymentProviderName") !== "null") {
            setPaymentProviderName(sessionStorage.getItem("paymentProviderName"));
        }
    }, []);
    return (
        <div className="APIKeysComponentItemWrap">
            {revel && TData.keyName === "Secret Key" && <div className="sKeyRevealWarningWrap">
                <div className="warningHeading">
                    <img src={`${props.image_base_url}${keywarningicon}`} alt="keywarningicon" className="warningIconWrapCircle" />
                    <div className="warningHeading_title">Warning</div>
                </div>
                <div className="sKeyRevealWarningText">{`You must keep your secret API key confidential and only store them on your own servers. You must not share your secret API key with any third parties. Your account’s secret API key can perform any API request to ${paymentProviderName ? paymentProviderName : "Crypton"} Payments without restriction.`} </div>
            </div>}
            <div className="APIKeysTable_item">{TData.keyName}</div>
            {TData.hide ? <div className="APIKeysTable_item">{revel
                ? <div className="APIKeysTable_itemTokenWrap">
                    <div className="APIKeysTable_itemToken" data-e2e="testKeys">
                        <span className="cursor-pointer">
                            <span onClick={() => {
                                toggleDropdown(!showDropdown)
                            }}>{TData.token.substring(0, 25) + "..."}</span>
                            <i onClick={() => {
                                toggleDropdown(!showDropdown)
                            }} data-e2e="secretKey" className={`fa fa-angle-${showDropdown ? "up" : "down"} APIKeysTable_item_dropIcon`} />
                        </span>
                        &nbsp;&nbsp;<span data-e2e="revealAndHideBtn" className="revealAndHideBtn" onClick={() => {
                            toggleRevelFlag(false)
                            toggleDropdown(false)
                        }}>Hide</span>
                        {showDropdown && <div className="APIKeysTable_itemTokenDropdownContent" data-e2e={TData.token}>{TData.token}</div>}
                    </div>
                </div>
                : <div className="revealAndHideBtn"
                    onClick={() => {
                        toggleRevelFlag(true)
                        if (!revealedDate) {
                            updateKeyRevealed()
                        }
                    }} data-e2e="revealSecretKey">Reveal Secret Key</div>
            }</div>
                : <div className="APIKeysTable_item">
                    <div className="APIKeysTable_itemToken" data-e2e="testKeys">
                        <span className="cursor-pointer" onClick={() => {
                            toggleDropdown(!showDropdown)
                        }}>{TData.token.substring(0, 25) + "..."}</span>
                        <i onClick={() => {
                            toggleDropdown(!showDropdown)
                        }} className={`fa fa-angle-${showDropdown ? "up" : "down"} APIKeysTable_item_dropIcon cursor-pointer`} />
                        {showDropdown && <div className="APIKeysTable_itemTokenDropdownContent">{TData.token}</div>}
                    </div>
                </div>
            }
            <div>Created:&nbsp;{TData.created ? moment(TData.created, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("D MMMM") : "N/A"}</div>
            {TData.keyName === "Secret Key" && <div>Revealed Date:&nbsp;{revealedDate && TData.keyName === "Secret Key" ? moment(revealedDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("D MMMM") : "-"}</div>}
            <div>Last Used:&nbsp;{TData.lastUsed ? moment(TData.lastUsed, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("D MMMM") : "-"}</div>
        </div>
    )
}