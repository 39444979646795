import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../Redux/actions';
import HeaderAndFooterLayout from '../../Layouts/HeaderAndFooter/HeaderAndFooterLayout';
import APIKeys from './APIKeys';
import Automation from './Automation';
import Balances from './Balances';
import Settings from './Settings';
import Transactions from './Transactions';
import Invoice from './Invoice';
import { useParams } from 'react-router-dom';

let myAccNavTabs = [
  {
    name: 'User Transactions',
    path: '/myAccount/transactions',
  },
  {
    name: 'Settings',
    path: '/myAccount/settings',
  },
  {
    name: 'API Keys',
    path: '/myAccount/apiKeys',
  },
];

let myAccNavTabs2 = [
  {
    name: 'Balances',
    path: '/myAccount/balances',
  },
  {
    name: 'User Transactions',
    path: '/myAccount/transactions',
  },
  {
    name: 'Settings',
    path: '/myAccount/settings',
  },
  {
    name: 'API Keys',
    path: '/myAccount/apiKeys',
  },
  {
    name: 'Automation',
    path: '/myAccount/automation',
  },
  {
    name: 'Invoice',
    path: '/myAccount/invoice',
  },
];

class MyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      merchantName: null,
      profileImage: null,
      paymentProviderId: null,
      domainId: null,
    };
  }
  componentDidMount = async () => {
    if (localStorage.getItem('merchantWalletActivation') && localStorage.getItem('merchantWalletActivation') != 'false') {
      myAccNavTabs = myAccNavTabs2;
    } else {
      if (this.props.params.myAccountTab === 'balances' || this.props.params.myAccountTab === 'automation') {
        this.props.navigate('/myAccount/transactions');
      }
    }
    if (localStorage.getItem('merchantName')) {
      this.setState({ merchantName: localStorage.getItem('merchantName') });
    }
    if (sessionStorage.getItem("paymentProviderId") && sessionStorage.getItem("paymentProviderId") !== '' && sessionStorage.getItem("paymentProviderId") !== 'null') {
      this.setState({ paymentProviderId: sessionStorage.getItem("paymentProviderId") });
    }
    if (sessionStorage.getItem("domainId") && sessionStorage.getItem("domainId") !== '' && sessionStorage.getItem("domainId") !== 'null') {
      this.setState({ domainId: sessionStorage.getItem("domainId") });
    }
    this.getMerchantProfileImage();
  };
  componentDidUpdate() {
    if (localStorage.getItem('merchantName') && localStorage.getItem('merchantName') !== this.state.merchantName) {
      this.setState({ merchantName: localStorage.getItem('merchantName') });
    }
  }
  getActiveTab = (name) => {
    switch (name) {
      case 'apiKeys':
        return 'API Keys';
      case 'transactions':
        return 'User Transactions';
      case 'automation':
        return 'Automation';
      case 'settings':
        return 'Settings';
      case 'invoice':
        return 'Invoice';
      default:
        return 'Balances';
    }
  };
  arrayBufferToBase64 = (buffer) => {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  };

  changeProfileImage = (e) => {
    let fd = new FormData();
    fd.append('email', localStorage.getItem("user_name"));
    fd.append('profileImage', e.target.files[0]);

    this.props.setLoading(true);
    fetch((window.location.hostname === 'localhost' ? this.props.api_url : '') + '/restapi/upload-merchant-profile-image', {
      method: 'POST',
      body: fd,
      headers: { 'x-access-token': localStorage.getItem('token') },
    })
      .then((res) => res.json())
      .then((data) => {
        this.props.setLoading(false);
        if (data.merchantUser && data.merchantUser.profileImage && data.merchantUser.profileImage !== '') {
          var base64Flag = `data:${data.merchantUser.profileImage.mimetype};base64,`;
          if (typeof data.merchantUser.profileImage.data === 'string') {
            var imageStr = data.merchantUser.profileImage.data;
          } else {
            var imageStr = this.arrayBufferToBase64(data.merchantUser.profileImage.data.data);
          }
          this.setState({
            profileImage: base64Flag + imageStr,
          });
        }
      })
      .catch((err) => {
        this.props.setLoading(false);
        console.log('error:', err);
      });
  };
  getMerchantProfileImage = (e) => {
    let fd = new FormData();
    fd.append('email', localStorage.getItem("user_name"));
    this.props.setLoading(true);
    fetch((window.location.hostname === 'localhost' ? this.props.api_url : '') + '/restapi/get-merchant-profile-image', {
      method: 'POST',
      body: fd,
      headers: { 'x-access-token': localStorage.getItem('token') },
    })
      .then((res) => res.json())
      .then((data) => {
        this.props.setLoading(false);
        if (data.merchantUser && data.merchantUser.profileImage && data.merchantUser.profileImage !== '') {
          var base64Flag = `data:${data.merchantUser.profileImage.mimetype};base64,`;
          if (typeof data.merchantUser.profileImage.data === 'string') {
            var imageStr = data.merchantUser.profileImage.data;
          } else {
            var imageStr = this.arrayBufferToBase64(data.merchantUser.profileImage.data.data);
          }
          this.setState({
            profileImage: base64Flag + imageStr,
          });
        }
      })
      .catch((err) => {
        this.props.setLoading(false);
        console.log('error:', err);
      });
  };

  render() {
    return (
      <HeaderAndFooterLayout>
        <div className="CP_myAccounts_main">
          <div
            className="myAccountHeaderSection"
            style={{
              ...(this.state.paymentProviderId && this.state.paymentProviderId !== '' && this.state.paymentProviderId !== 'null'
                ? { backgroundPositionX: '0px', backgroundPositionY: '0px' }
                : {}),
              backgroundImage: `url(${this.props.image_base_url}${
                this.state.paymentProviderId && this.state.paymentProviderId !== '' && this.state.paymentProviderId !== 'null'
                  ? `/payment-provider/${this.state.paymentProviderId}${this.state.domainId ? '/' + this.state.domainId : ''}/bg${
                      this.props.theme === 'light' ? '' : 'dark'
                    }.png`
                  : ''
              })`,
            }}
          >
            <div className="myAccountHeaderSectionWrap">
              <MyAccountHeader
                merchantDetails={{ profileImage: this.state.profileImage }}
                changeProfileImage={this.changeProfileImage}
                merchantName={this.state.merchantName}
              />
            </div>
            <MyAccountNavTabs
              activeTab={this.getActiveTab(this.props.params.myAccountTab)}
              redirectTo={(path) => {
                this.props.navigate(path);
              }}
            />
          </div>
          <div className={`myAccountTabsWrap ${this.props.params.myAccountTab === 'balances' ? 'mer-trans-pad' : ''}`}>
            {this.props.params.myAccountTab === 'balances' && <Balances className="myAccountTabContent" />}
            {this.props.params.myAccountTab === 'transactions' && (
              <Transactions refTransactionId={this.props.params.transactionId} className="myAccountTabContent" />
            )}
            {this.props.params.myAccountTab === 'settings' && <Settings className="myAccountTabContent" />}
            {this.props.params.myAccountTab === 'apiKeys' && <APIKeys className="myAccountTabContent" />}
            {this.props.params.myAccountTab === 'automation' && <Automation className="myAccountTabContent" />}
            {this.props.params.myAccountTab === 'invoice' && <Invoice className="myAccountTabContent" />}
          </div>
        </div>
      </HeaderAndFooterLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api_url: state.config.api_url,
    theme: state.common.theme,
    image_base_url: state.config.image_base_url,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (data) => dispatch(actions.setLoading(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)((props) => (
  <MyAccount
      {...props}
      params={useParams()}
  />
));

const MyAccountHeader = (props) => {
  const { profileImage } = props.merchantDetails;
  return (
    <div>
      <div className="myAccHeaderMain">
        <div className="subContainer">
          <div className="myAccDetailsMain">
            <div className="myAccDetailsWrap">
              {profileImage ? (
                <span style={{ position: 'relative' }}>
                  <img className="myAccMerchantIcon" src={profileImage} />
                  <div
                    className="myAccMerchantIcon"
                    style={{
                      position: 'absolute',
                      backgroundColor: 'transparent',
                      bottom: '0px',
                    }}
                  >
                    <div className="myAccMerchantIconEdit">
                      <i className="fa fa-camera" />
                    </div>
                    <input
                      type="file"
                      className="myAccMerchantIconEdit myAccMerchantIconInput"
                      accept="image/x-png,image/jpeg,image/jpg,image/png"
                      data-max-size="5000000"
                      onChange={(e) => props.changeProfileImage(e)}
                    />
                  </div>
                </span>
              ) : (
                <div className="myAccMerchantIcon">
                  <div className="myAccMerchantIconEdit">
                    <i className="fa fa-camera" />
                  </div>
                  <input
                    type="file"
                    className="myAccMerchantIconEdit myAccMerchantIconInput"
                    data-max-size="5000000"
                    accept="image/x-png,image/jpeg,image/jpg,image/png"
                    onChange={(e) => props.changeProfileImage(e)}
                  />
                </div>
              )}
              <div className="myAccMerchantName">{props.merchantName ? props.merchantName : 'Merchant'}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MyAccountNavTabs = (props) => {
  const { activeTab } = props;
  return (
        <div className="navTabsWrap">
          {myAccNavTabs.map(({ name, path }, i) => {
            return (
              <div
                key={i}
                className={`navTabBtn ${activeTab === name ? 'navTabBtnActive ' : ''}`}
                onClick={() => {
                  props.redirectTo(path);
                }}
                data-e2e={name}
              >
                {name}
              </div>
            );
          })}
        </div>
  );
};
