import React, { useState, useEffect } from 'react';
import { apiService } from '../../../common/apiCallService';
import InvoiceTable from './InvoiceTable';
import * as actions from '../../../Redux/actions';
import CreateInvoice from './CreateInvoice';
import InvoiceDetails from './InvoiceDetails';
import InvoicePaymentRequest from './InvoicePaymentRequest';
import { useSelector, useDispatch } from 'react-redux';
import { checkValueInSS, getHeaderButtonBorderStyle, getHeaderButtonStyle } from '../../../common/theme';

const Invoice = (props) => {
  const [page, setPage] = useState(1);
  const [merchantInvoice, setMerchantInvoice] = useState([]);
  const [invoiceDetailsData, setInvoiceDetailsData] = useState([]);
  const [orderBy, setOrderBy] = useState('asc');
  const [sortBy, setSortBy] = useState('creationDate');
  const [totalCount, setTotalCount] = useState(0);
  const [openTransactionDetails, setOpenTransactionDetails] = useState(false);
  const [open, setOpen] = useState(false);
  const [openPaymentRequest, setOpenPaymentRequest] = useState(false);
  const [openInvoiceDetails, setOpenInvoiceDetails] = useState(false);
  const [allCryptos, setAllCryptos] = useState([]);
  const [invoiceResponseData, setInvoiceResponseData] = useState(null);
  const [clearInputFields, setClearInputFields] = useState(false);
  const [allPaymentSettings, setAllPaymentSettings] = useState(['Accept Exact Amount', 'Accept Any Amount']);
  const [colorMode, setColorMode] = useState(null);
  const [headerButtonDarkBgColour, setheaderButtonDarkBgColour] = useState(null);
  const [headerButtonLightBgColour, setheaderButtonLightBgColour] = useState(null);
  const [headerDarkTextColour, setheaderDarkTextColour] = useState(null);
  const [headerLightTextColour, setheaderLightTextColour] = useState(null);

  const api_url = useSelector((state) => state.config.api_url);
  const image_base_url = useSelector((state) => state.config.image_base_url);
  const cryptos = useSelector((state) => state.transaction.cryptos);
  const selectedCryptos = useSelector((state) => state.common.selectedCryptos);
  const selectedPaymentSettings = useSelector((state) => state.common.selectedPaymentSettings);

  const dispatch = useDispatch();

  const setLoading = (data) => {
    dispatch(actions.setLoading(data));
  };

  const saveCryptos = (data) => {
    dispatch(actions.saveCryptos(data));
  };
  const setSelectedCryptosAction = (data) => {
    dispatch(actions.setSelectedCryptos(data));
  };
  const setPaymentSettingsAction = (data) => {
    dispatch(actions.setSelectedPaymentSettings(data));
  };
  const getCryptoCoins = () => {
    let requestBody = {};
    setLoading(true);
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/get-cryptos',
      requestBody,
      (data) => {
        setLoading(false);
        setAllCryptos(data.cryptos);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      },
    );
  };
  const getMerchantInvoiceData = (req) => {
    let requestBody = {
      merchantId: localStorage.getItem('merchantId'),
      page: page,
      email: localStorage.getItem("user_name"),
    };
    setLoading(true);
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/get-merchant-invoices',
      req ? req : requestBody,
      (data) => {
        setLoading(false);
        setMerchantInvoice(data.merchantInvoiceData);
        setTotalCount(data.totalCount);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      },
    );
  };
  useEffect(() => {
    if (localStorage.getItem("user_name")) {
      getCryptoCoins();
      getMerchantInvoiceData();
    }
    if (checkValueInSS("colorMode")) {
      setColorMode(sessionStorage.getItem("colorMode"));
    }
    if (checkValueInSS("headerButtonDarkBgColour")) {
      setheaderButtonDarkBgColour(sessionStorage.getItem("headerButtonDarkBgColour"));
    }
    if (checkValueInSS("headerButtonLightBgColour")) {
      setheaderButtonLightBgColour(sessionStorage.getItem("headerButtonLightBgColour"));
    }
    if (checkValueInSS("headerDarkTextColour")) {
      setheaderDarkTextColour(sessionStorage.getItem("headerDarkTextColour"));
    }
    if (checkValueInSS("headerLightTextColour")) {
      setheaderLightTextColour(sessionStorage.getItem("headerLightTextColour"));
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("user_name")) {
      getMerchantInvoiceData();
    }
  }, [page]);
  const handleCreateInvoiceModal = () => {
    setOpen(true);
    setOpenInvoiceDetails(false);
    setClearInputFields(false);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenInvoiceDetails(false);
    setOpenPaymentRequest(false);
    setInvoiceDetailsData([]);
    setClearInputFields(true);
  };
  const handleOrderByChange = () => {
    setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');
  };
  const handleSortByChange = (newSortBy) => {
    setSortBy(newSortBy);
    setOrderBy('asc');
  };

  const handlePageChange = () => {
    setPage(page + 1);
  };
  const handleBack = () => {
    setOpen(true);
    setOpenInvoiceDetails(false);
  };
  const handleConfirm = () => {
    const { hostname } = window.location;
    let requestBody = {
      merchantId: localStorage.getItem('merchantId'),
      page: page,
      paymentReference: invoiceDetailsData[2] && invoiceDetailsData[2].value,
      merchantUserEmail: invoiceDetailsData[7] && invoiceDetailsData[7].value,
      paymentSettings: invoiceDetailsData[4] && invoiceDetailsData[4].amount,
      cryptoCurrencies: invoiceDetailsData[5] && invoiceDetailsData[5].amount,
      USDAmount: Number(invoiceDetailsData[1] && invoiceDetailsData[1].amount),
      fiatCurrency: invoiceDetailsData[6] && invoiceDetailsData[6].value,
      invoiceURL: `https://${hostname}/cryptoToDeposit`,
      email: localStorage.getItem("user_name"),
    };
    setLoading(true);
    apiService(
      (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/create-merchant-invoice',
      requestBody,
      (data) => {
        setInvoiceResponseData(data);
        setLoading(false);
        setOpenInvoiceDetails(false);
        setOpenPaymentRequest(true);
        getMerchantInvoiceData();
      },
      (err) => {
        setLoading(false);
        console.log(err);
      },
    );
  };
  const getInvoices = (pageNo) => {
    let page = 1;
    if (pageNo) {
      page = pageNo;
      setPage(pageNo);
    }
    let requestBody = {
      merchantId: localStorage.getItem('merchantId'),
      email: localStorage.getItem("user_name"),
      page,
      orderBy,
      sortBy,
    };
    getMerchantInvoiceData(requestBody);
  };

  let headerButtonBorderStyle = getHeaderButtonBorderStyle(colorMode, headerButtonDarkBgColour, headerButtonLightBgColour)
  let headerButtonStyle = getHeaderButtonStyle(colorMode, headerButtonDarkBgColour, headerDarkTextColour, headerButtonLightBgColour, headerLightTextColour)

  return (
    <div className='s'>
      <button data-e2e="create-invoice-btn" className="create_invoice_btn" style={{ ...headerButtonStyle }} onClick={handleCreateInvoiceModal}>
        Create New Invoice
      </button>
      <CreateInvoice
        open={open}
        handleClose={handleClose}
        selectedCryptos={selectedCryptos}
        allCryptos={allCryptos}
        setSelectedCryptos={setSelectedCryptosAction}
        setPaymentSettings={setPaymentSettingsAction}
        selectedPaymentSettings={selectedPaymentSettings}
        allPaymentSettings={allPaymentSettings}
        setOpenInvoiceDetails={setOpenInvoiceDetails}
        setOpen={setOpen}
        setInvoiceDetailsData={setInvoiceDetailsData}
        clearInputFields={clearInputFields}
      />
      <InvoiceDetails
        open={openInvoiceDetails}
        handleClose={handleClose}
        handleBack={handleBack}
        handleConfirm={handleConfirm}
        invoiceDetailsData={invoiceDetailsData}
        allCryptos={allCryptos}
        selectedCryptos={selectedCryptos}
        image_base_url={image_base_url}

      />
      {openPaymentRequest && (
        <InvoicePaymentRequest
          open={openPaymentRequest}
          handleClose={handleClose}
          invoiceDetailsData={invoiceDetailsData}
          invoiceURL={invoiceResponseData && invoiceResponseData.invoiceURL}
          orderId={invoiceResponseData && invoiceResponseData.orderId}
          allCryptos={allCryptos}
          selectedCryptos={selectedCryptos}
          image_base_url={image_base_url}
        />
      )}
      <InvoiceTable
        data={merchantInvoice}
        sortBy={sortBy}
        orderBy={orderBy}
        changeOrder={(sortBy) => {
          if (sortBy !== sortBy) {
            handleSortByChange(sortBy);
            getInvoices(1);
          } else {
            handleOrderByChange();
            getInvoices(1);
          }
        }}
      />
      {totalCount < 1 && <div className="No_Transactions_Found">No Invoice Found</div>}
      {totalCount && merchantInvoice && merchantInvoice.length < totalCount ? (
        <div className="Show_more_transactions_wrap">
          <div className="Show_more_transactions" style={{ ...headerButtonBorderStyle}} onClick={() => handlePageChange()}>
            See All Invoices
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Invoice;
