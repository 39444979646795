import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../../common/apiCallService';
import PayLoading from "./PayLoading";
import { connect, useSelector } from 'react-redux';
import * as actions from '../../../Redux/actions/index';
import { getDeviceIP } from '../../../common/getIP';
import { useTranslation } from 'react-i18next';
import { constants } from '../../../common/constants';
import PaymentSummaryForCreditCardBuy from '../B2C/UserWalletsHome/PaymentSummaryForCreditCardBuy';

const CreditCardPayment = (props) => {
    const [creditCardNumber, setCreditCardNumber] = useState("");
    const payment_api_url = useSelector((state) => state.config.payment_api_url);
    const api_url = useSelector((state) => state.config.api_url);
    const coinSpiritHostName = useSelector((state) => state.config.COIN_SPIRIT_HOST_NAME);
    const coinSpiritMailSupport = useSelector((state) => state.config.COIN_SPIRIT_MAIL_SUPPORT);
    const [name, setName] = useState("");
    const [expiryDate, setExpiryDate] = useState("");
    const [CVVValue, setCVVValue] = useState("");
    const [payerAddress, setPayerAddress] = useState("");
    const [payerState, setPayerState] = useState("");
    const [payerCountry, setPayerCountry] = useState("");
    const [payerCity, setPayerCity] = useState("");
    const [payerZip, setPayerZip] = useState("");
    const [payerPhone, setPayerPhone] = useState("");
    const [saveCard, setSaveCard] = useState(false);
    const [error, setError] = useState("");
    const cardInput = useRef(null);
    const [token, setToken] = useState("");
    const [getTokenLoading, setGetTokenLoading] = useState(false)
    const [payFlag, setPayFlag] = useState(false);
    const navigate = useNavigate();
    const [acquirerName, setAcquirerName] = useState("");
    const [routingId, setRoutingId] = useState("");
    const { t } = useTranslation()
    const [countryCode, setcountryCode] = useState([]);
    useEffect(() => {
        if (cardInput.current) {
            cardInput.current.focus();
        }

        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') + '/restapi/get-country-code',
            {},
            (data) => {
                setcountryCode(data)
            })
    }, [])

    const getCardNumentMasked = (val) => {
        var newval = '';
        val = val.replace(/\s/g, '');
        for (var i = 0; i < val.length; i++) {
            if (i % 4 == 0 && i > 0) newval = newval.concat('  ');
            newval = newval.concat(val[i]);
        }
        return newval
    }

    const handleCommonSuccessApiResponse = (data) => {
        setPayFlag(false);
        if (data) {
            setGetTokenLoading(false)
            if (data.redirectUrl) {
                console.log("data.redirectUrl 5", data.redirectUrl, data.acquirerRedirect)
                navigate(data.redirectUrl)
            } else if (data.acquirerRedirect) {
                console.log("data.redirectUrl 6", data.redirectUrl, data.acquirerRedirect)
                window.location.href = data.acquirerRedirect
            } else {
                setToken(data);
                setPayFlag(true);
                props.handleLoadingModal();
            }
        }
    }

    const handleCommonSuccessApiResponseSimple = (data) => {
        if (data) {
            setToken(data);
            setPayFlag(true);
            props.handleLoadingModal();
        }
    }

    const commomErrorResponseHandler = (err) => {
        setError("Something Went Wrong!")
        console.error("errrr", err)
        setGetTokenLoading(false)
        setPayFlag(false);
        if (err.responseJSON && err.responseJSON.redirectUrl) {
            navigate(err.responseJSON.redirectUrl)
        } else {
            navigate("/creditCard/depositFailed")
        }
    }

    const submitData = async () => {
        let ipAddress = await getDeviceIP()
        let date = expiryDate.substring(0, 2) + "/20" + expiryDate.substring(2, 4)
        let query = {
            name,
            expiryDate: date,
            CVVValue,
            creditCardNumber,
            fiatAmount: props.fiatAmount,
            fiatCurrency: props.fiatCurrency,
            saveCard: saveCard,
            payerAddress,
            payerState,
            payerCountry,
            payerCity,
            payerZip,
            ipAddress,
            payerPhone,
            transactionId: localStorage.getItem("transactionId"),
            "browser_timezone": new Date().toString().match(/([-\+][0-9]+)\s/)[1],
            "browser_color_depth": window.screen.colorDepth,
            "browser_language": navigator.language,
            "browser_screen_height": window.innerHeight,
            "browser_screen_width": window.innerWidth,
            "os": navigator.platform,
            "browserUserAgent": navigator.userAgent,
        }
        setGetTokenLoading(true)
        await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/getCardStatus', query, async (data) => {
            if (data) {
                if (data.code == "ACCEPT") {
                    query.fiatAmount = data.finalAmount ? data.finalAmount : props.fiatAmount;
                    props.setCardAPIType(data.integrationType)
                    setRoutingId(data.routingId)
                    setAcquirerName(data.acquirerName)
                    if (data.integrationType === "Trust Payments") {
                        await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/getToken', { ...query, routingId: data.routingId, acquirerName: data.acquirerName }, async (data) => {
                            handleCommonSuccessApiResponseSimple(data)
                        }, (err) => {
                            commomErrorResponseHandler(err);
                        })
                    } else if (data.integrationType === "Brava Payments") {
                        let filterdata = countryCode.filter((e) => e.name === query.payerCountry)
                        await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/createCardPaymentBrava',
                            {
                                ...query,
                                payerCountry: filterdata[0].code,
                                routingId: data.routingId,
                                acquirerName: data.acquirerName,
                                domain: window.location.hostname === 'localhost' ? "http://localhost:8081" : window.location.origin
                            }, async (data) => {
                                if (data) {
                                    setGetTokenLoading(false)
                                    if (data.redirectUrl) {
                                    console.log("data.redirectUrl 3", data.redirectUrl, data.acquirerRedirect)
                                        navigate(data.redirectUrl)
                                    } else if (data.acquirerRedirect) {
                                    console.log("data.redirectUrl 4", data.redirectUrl, data.acquirerRedirect)
                                        window.location.href = data.acquirerRedirect
                                    } else {
                                        setToken(data);
                                        setPayFlag(true);
                                        props.handleLoadingModal();
                                    }
                                }
                            }, (err) => {
                                setError("Something Went Wrong!")
                                console.error("errrr", err)
                                if (err?.responseJSON?.redirectUrl) {
                                    navigate(err.responseJSON.redirectUrl)
                                } else {
                                    navigate("/creditCard/depositFailed")
                                }
                            })
                    } else if (data.integrationType === constants.GOLDEN_GATE_PAYMENTS_INTEGRATION_TYPE) {
                        let reqData = {
                            ...query,
                            "amount": data.finalAmount ? data.finalAmount : props.fiatAmount,
                            "description": "Payment",
                            "nameOnCard": name,
                            "expirationYear": "20" + expiryDate.substring(2, 4),
                            "expirationMonth": expiryDate.substring(0, 2),
                            "routingId": data.routingId,
                            "acquirerName": data.acquirerName,
                        }
                        await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/goldenGatewayCardPayment', reqData, async (data) => {
                            if (data) {
                                if (saveCard && ((data.redirectUrl && data.redirectUrl.includes("/creditCard/depositSuccessful")) || data.acquirerRedirectUrl)) {
                                    let tokenizeData = {
                                        ...query,
                                        "nameOnCard": name,
                                        "expirationYear": "20" + expiryDate.substring(2, 4),
                                        "expirationMonth": expiryDate.substring(0, 2),
                                        "paymentMethodBrand": data.paymentMethodBrand,
                                        "typeOfTokenization": "saveCard", // deleteCard
                                        "routingId": data.routingId,
                                        "acquirerName": data.acquirerName,
                                    }
                                    await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/goldenGatewayCardTokenize', tokenizeData, async (res) => {
                                        if (res) {
                                            console.log("card tokenized success")
                                        }
                                    }, (err) => {
                                        setError("Something Went Wrong!")
                                        console.error("errrr", err)
                                    })
                                }
                                if (data.acquirerRedirectUrl) {
                                    console.log("data.redirectUrl 11", data.redirectUrl, data.acquirerRedirectUrl)
                                    window.location.href = data.acquirerRedirectUrl
                                } else {
                                    console.log("data.redirectUrl 12", data.redirectUrl, data.acquirerRedirectUrl)
                                    navigate(data.redirectUrl)
                                    props.handleLoadingModal();
                                }
                            }
                        }, (err) => {
                            commomErrorResponseHandler(err);
                        })
                    } else if (data.integrationType === "AltPayNet") {
                        let reqData = {
                            ...query,
                            "routingId": data.routingId,
                            "acquirerName": data.acquirerName,
                        }
                        await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/createCardPaymentAltPayNet', reqData, async (data) => {
                            handleCommonSuccessApiResponse(data);
                        }, (err) => {
                            commomErrorResponseHandler(err);
                        })
                    } else if (data.integrationType === "Pay.com") {
                        setPayFlag(true);
                        let reqData = {
                            ...query,
                            "routingId": data.routingId,
                            "acquirerName": data.acquirerName,
                        }
                        await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/createCardPaymentPayDotCom', reqData, async (data) => {
                            handleCommonSuccessApiResponse(data);
                        }, (err) => {
                            commomErrorResponseHandler(err);
                        })
                    } else if (data.integrationType === "Axcess Payments") {    
                        await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/getAxcessPaymentSignature', {
                            ...query,
                            routingId: data.routingId,
                            acquirerName: data.acquirerName,
                            domain: window.location.hostname === 'localhost' ? "http://localhost:8081" : window.location.origin
                        }, async (data) => {
                            handleCommonSuccessApiResponseSimple(data)
                        }, (err) => {
                            commomErrorResponseHandler(err);
                        })
                    } else if (data.integrationType === constants.WORLD_CARD_INTEGRATION_TYPE) {
                        await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/cardPaymentWorldCard', {
                            ...query,
                            routingId: data.routingId,
                            acquirerName: data.acquirerName,
                            domain: window.location.hostname === 'localhost' ? "http://localhost:8081" : window.location.origin
                        }, async (data) => {
                            handleCommonSuccessApiResponse(data)
                        }, (err) => {
                            commomErrorResponseHandler(err);
                        })
                    } else if (data.integrationType === constants.BAERS_CREST_INTEGRATION_TYPE) {
                        await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/cardPaymentBaersCrest', {
                            ...query,
                            routingId: data.routingId,
                            acquirerName: data.acquirerName,
                            domain: window.location.hostname === 'localhost' ? "http://localhost:8081" : window.location.origin
                        }, async (data) => {
                            handleCommonSuccessApiResponse(data)
                        }, (err) => {
                            commomErrorResponseHandler(err);
                        })
                    } else if (data.integrationType === constants.PAYMENT_EMPIRE_INTEGRATION_TYPE) {
                        await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/paymentEmpireCardPayment', {
                            ...query,
                            routingId: data.routingId,
                            acquirerName: data.acquirerName,
                            domain: window.location.hostname === 'localhost' ? "http://localhost:8081" : window.location.origin
                        }, async (data) => {
                            handleCommonSuccessApiResponse(data)
                        }, (err) => {
                            commomErrorResponseHandler(err);
                        })
                    } else if (data.integrationType === constants.NEVO4_INTEGRATION_TYPE) {
                        await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/cardPaymentNevo4', {
                            ...query,
                            routingId: data.routingId,
                            acquirerName: data.acquirerName,
                            domain: window.location.hostname === 'localhost' ? "http://localhost:8081" : window.location.origin
                        }, async (data) => {
                            handleCommonSuccessApiResponse(data)
                        }, (err) => {
                            commomErrorResponseHandler(err);
                        })
                    } else if (data.integrationType === constants.FINTECH_INTEGRATION_TYPE) {
                        await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/fintechCardPayment', {
                            ...query,
                            routingId: data.routingId,
                            acquirerName: data.acquirerName,
                            domain: window.location.hostname === 'localhost' ? "http://localhost:8081" : window.location.origin
                        }, async (data) => {
                            setPayFlag(false);
                            if (data) {
                                setGetTokenLoading(false)
                                if (data.redirectUrl) {
                                    navigate(data.redirectUrl)
                                } else if (data.acquirerRedirect) {
                                    const popupWindow = window.open(data.acquirerRedirect, '_blank')
                                    try {
                                        popupWindow.focus();
                                        navigate("/checkCardPayTransactionStatus?transactionId=" + query.transactionId + "&merchantId=" + data.merchantId)
                                    } catch (e) {
                                        alert("Pop-up Blocker is enabled! Please add this site to your exception list.");
                                    }
                                } else {
                                    setToken(data);
                                    setPayFlag(true);
                                    props.handleLoadingModal();
                                }
                            }
                        }, (err) => {
                            commomErrorResponseHandler(err);
                        })
                    }else if(data.integrationType === constants.SWITCH_FIN_INTEGRATION_TYPE){
                    //   Do the redirection here
                    await apiService((window.location.hostname === "localhost" ? payment_api_url : "") + '/pga/cardPaymentswitchFin', {
                        ...query,
                        routingId: data.routingId,
                        acquirerName: data.acquirerName,
                        domain: window.location.hostname === 'localhost' ? "http://localhost:8081" : window.location.origin
                    }, async (data) => {
                        handleCommonSuccessApiResponse(data)
                    }, (err) => {
                        commomErrorResponseHandler(err);
                    })
                    }
                } else {
                    setError("This card is blocked")
                    setGetTokenLoading(false)
                    navigate("/creditCard/depositFailed");
                }
            }
        }, (err) => {
            console.error("errrr", err)
            setError("Something Went Wrong! (API Error)")
        })
    }
    const getExpiryDateMasked = (val) => {
        var newval = '';
        val = val.replace(/\s/g, '');
        for (var i = 0; i < val.length; i++) {
            if (i % 2 == 0 && i > 0 && i < 3) newval = newval.concat(' / ');
            newval = newval.concat(val[i]);
        }
        return newval
    }

    const checkCoinsSpirit = () => {
        return (window && window.location && window.location.hostname &&
            window.location.hostname.includes(coinSpiritHostName)) || false;
    };

    let disabledCondition = (CVVValue && CVVValue.length === 3 && !isNaN(CVVValue) &&
        creditCardNumber && creditCardNumber.length === 16 && !isNaN(creditCardNumber) &&
        expiryDate && expiryDate.length === 4 && !isNaN(expiryDate) && name && name.length > 2
        && payerAddress && payerAddress !== ""
        && payerState && payerState !== ""
        && payerCity && payerCity !== ""
        && payerCountry && payerCountry.length >= 2
        && payerZip && payerZip !== ""
        && payerPhone && payerPhone !== ""
        && !getTokenLoading) ? true : false

    let requiredComp = <span style={{ color: "red" }}>*</span>

    return (
        <div className={`creditCardContainer ${props.lightFlag ? "text_color_black" : "text_color_white"}`} id={checkCoinsSpirit() ? "creditCardContainerWidth" : ""}>
            <div className='creditCardPaymentSummary header__profile_block'>
                <PaymentSummaryForCreditCardBuy {...props} />
                <div className={checkCoinsSpirit() ? "creditCardPaymentSummaryDetailsPaymentMethodSelection" : ""}>
                    {!payFlag && <div className={`creditCardPaymentFiled`}>
                        <div className={`creditCardInputWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                            <div className="title">{t('Card Number')} {requiredComp}</div>
                            <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""} ${props.lightFlag ? "text_color_black" : "text_color_white"}`}>
                                <input type="text"
                                    value={getCardNumentMasked(creditCardNumber)}
                                    onChange={(e) => {
                                        setCreditCardNumber((e.target.value.replace(/\s/g, '')).replace(/\D[^\.]/g, "").substring(0, 16))
                                        setError("")
                                    }}
                                    ref={cardInput}
                                    pattern="[0-9]+"
                                    data-e2e="card-number"
                                    className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                                    placeholder="••••  ••••  ••••  ••••" />
                            </div>
                        </div>
                        <div className={`creditCardInputWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                            <div className="title">{t('Name on Card')} {requiredComp}</div>
                            <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                                <input type="text"
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value)
                                        setError("")
                                    }}
                                    className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                                    placeholder={t("Enter card holders name")} />
                            </div>
                        </div>
                        <div className="creditCardInputContainer">
                            <div className={`creditCardInputWrap creditCardInputExpiryWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                                <div className="title">{t('Expiry date')} {requiredComp}</div>
                                <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                                    <input type="text"
                                        value={getExpiryDateMasked(expiryDate)}
                                        onChange={(e) => {
                                            setExpiryDate((e.target.value.replace(/\s/g, '')).replace(/[^0-9.]/g, "").substring(0, 4))
                                            setError("")
                                        }}
                                        className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                                        placeholder={t("MM / YY")} />
                                </div>
                            </div>
                            <div className={`creditCardInputWrap creditCardInputCVVWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                                <div className="title">CVV/CVC  {requiredComp}</div>
                                <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                                    <input type="text"
                                        value={CVVValue}
                                        onChange={(e) => {
                                            setCVVValue((e.target.value.replace(/\s/g, '')).replace(/[^0-9.]/g, "").substring(0, 3))
                                            setError("")
                                        }}
                                        className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                                        placeholder="123" />
                                </div>
                            </div>
                        </div>
                        <div className={`creditCardInputWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                            <div className="title">Address {requiredComp}</div>
                            <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                                <input type="text"
                                    value={payerAddress}
                                    onChange={(e) => {
                                        setPayerAddress(e.target.value)
                                        setError("")
                                    }}
                                    className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                                    placeholder="Enter address" />
                            </div>
                        </div>
                        <div className={`creditCardInputWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                            <div className="title">State {requiredComp}</div>
                            <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                                <input type="text"
                                    value={payerState}
                                    onChange={(e) => {
                                        setPayerState(e.target.value)
                                        setError("")
                                    }}
                                    className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                                    placeholder="Enter State" />
                            </div>
                        </div>
                        <div className={`creditCardInputWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                            <div className="title">Country Code {requiredComp}</div>
                            <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                                <select
                                    value={payerCountry}
                                    className={`${payerCountry !== "" ? props.lightFlag ? "text_color_black " : "text_color_white " : "select-default-option"} ${props.lightFlag ? "inputField" : "selectField"}`}
                                    onChange={(e) => {
                                        setPayerCountry(e.target.value)
                                        setError("")
                                    }}
                                >
                                    {payerCountry === "" &&
                                        <option style={{ height: "50px" }} value={""} disabled className='font-weight-light'>Select 2-digit contry code (e.g. US)</option>
                                    }
                                    {countryCode && countryCode.length > 0 && countryCode.map((data, index) => {
                                        return (
                                            <option key={index} style={{ height: "50px" }} className={`${props.lightFlag ? "text_color_black " : "text_color_white "}`} data-e2e={data.name} value={data.name}>{data.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className={`creditCardInputWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                            <div className="title">City {requiredComp}</div>
                            <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                                <input type="text"
                                    value={payerCity}
                                    onChange={(e) => {
                                        setPayerCity(e.target.value)
                                        setError("")
                                    }}
                                    className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                                    placeholder="Enter city name" />
                            </div>
                        </div>
                        <div className={`creditCardInputWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                            <div className="title">Zip Code {requiredComp}</div>
                            <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                                <input type="text"
                                    value={payerZip}
                                    onChange={(e) => {
                                        setPayerZip(e.target.value)
                                        setError("")
                                    }}
                                    className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                                    placeholder="Enter zip code" />
                            </div>
                        </div>
                        <div className={`creditCardInputWrap ${props.lightFlag ? "creditCardInputWrapLight" : ""}`}>
                            <div className="title">Phone {requiredComp}</div>
                            <div className={`inputWrap ${props.lightFlag ? "inputWrapLight" : ""}`}>
                                <input type="text"
                                    value={payerPhone}
                                    onChange={(e) => {
                                        setPayerPhone(e.target.value)
                                        setError("")
                                    }}
                                    className={`inputField ${props.lightFlag ? "text_color_black" : "text_color_white"}`}
                                    placeholder="Enter phone number" />
                            </div>
                        </div>
                        <div className="saveAndErrorWrap">
                            <div className="saveThisCard">
                                <div className={`tickBox ${props.lightFlag ? "tickBoxLight" : ""}`} onClick={() => {
                                    setSaveCard(!saveCard)
                                }}>
                                    {saveCard && <i className="fa fa-check-square" />}
                                </div>
                                <div className="title">{t('Save this card')}</div>
                            </div>
                            {error !== "" && <div className="errorMsg">{t(error)}</div>}
                        </div>
                    </div>}
                    {!payFlag && <div className={`ANC_Continue_btn ${disabledCondition ? "" : "ANC_Continue_btn_disabled"}`} onClick={() => {
                        if (disabledCondition) {
                            submitData();
                        } else if (!(creditCardNumber && creditCardNumber.length === 16 && !isNaN(creditCardNumber))) {
                            setError("Invalid Card Number")
                        } else if (!(expiryDate && expiryDate.length === 4 && !isNaN(expiryDate))) {
                            setError("Invalid Expiry Date")
                        } else if (!(CVVValue && CVVValue.length === 3 && !isNaN(CVVValue))) {
                            setError("Invalid CVC / CVV")
                        } else {
                            setError("Invalid Details!")
                        }

                    }}>{t('Deposit')}</div>}
                    <div className='creditCardFooterImages mt-10'>
                        <p>{`Contact support on ${coinSpiritMailSupport}`}</p>
                        <p className="footer__copyright__images">
                            <img loading="lazy" decoding="async" src={`${props.image_base_url}/upd/icons/mastercard-securecode.png`} alt="" width="96" height="54" />
                            <img loading="lazy" decoding="async" src={`${props.image_base_url}/upd/icons/mastercard-securecode1.png`} alt="" width="96" height="54" />
                        </p>
                    </div>
                </div>
            </div>
            {payFlag && <PayLoading
                customerName={name}
                cardNumber={creditCardNumber}
                cardExpiryMonth={expiryDate.substring(0, 2)}
                cardExpiryYear={expiryDate.substring(2, 4)}
                cardCVV={CVVValue}
                routingId={routingId}
                acquirerName={acquirerName}
                transactionId={localStorage.getItem("transactionId")}
                saveCard={saveCard} token={token}
                axcess_hosted_url={props.axcess_hosted_url} />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        cardAPIType: state.deposit.cardAPIType,
        axcess_hosted_url: state.config.axcess_hosted_url,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCardAPIType: (value) => dispatch(actions.setCardAPIType(value)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardPayment);